:root {
    --1grad-color: #6c63ff;
    --2grad-color: #BA63FF;
    --3grad-color: #63A8FF;
    --4text-color: #ffffff;
  }

.bg_gradient_purp {
    background: linear-gradient(135deg, var(--1grad-color), var(--2grad-color)); 
}

.bg_third {
    background: linear-gradient(135deg, var(--3grad-color), var(--3grad-color)); 
}

.contrast_theme_text {
    color: var(--4text-color) !important;
}

.blur_body {
    backdrop-filter: blur(10px); /* Adjust blur radius as needed */
    background-color: rgba(255, 255, 255, 0.4); /* Adjust the color and transparency as needed */
}

.purple_text {
    background: linear-gradient(to right, var(--1grad-color), var(--2grad-color));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.dropdown-no-arrow.dropdown-toggle::after {
    display: none;
}

.purple_spinner {
    color: var(--3grad-color);
}

.purple-button {
    background: linear-gradient(135deg, var(--1grad-color), var(--2grad-color));
    border: 0px solid transparent;
    color: #fff;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.15s ease-in-out;
}

.purple-button:focus,
.purple-button:hover {
    filter: brightness(0.8);
    border-color: #fff;
}

.nav-pills .nav-link.active {
    background-color: var(--3grad-color) !important;
}
.transition-col {
    transition: all 0.2s ease-in;
}

.image-transition {
    transition: width 0.3s ease-in-out;
  }

.nav-transition {
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  }

  .translate-in, .translate-out {
    transition: transform 0.2s ease-in;
  }

  .textTransition {
    transition: all 0.2s ease-in;
    transition-behavior: allow-discrete;
    opacity: 1;
  }

  .close {
    display: none;
    opacity: 0;
  }
  
  .translate-in {
    transform: translateX(0);
  }
  
  .translate-out {
    transform: translateX(-20px);
  }


.react-datepicker__day--selected {
    background:var(--3grad-color) !important;
  }

.react-datepicker__day--weekend {
    background: #f6f6f6;
}

.user_absence, .user_absence:hover {
    background: #f1280e !important;
}

.user_busy, .user_busy:hover {
    background: #f10e0e !important;
}

.job_confirmed, .job_confirmed:hover {
    background: #1f71ff !important;
}

.display-datepicker .react-datepicker__day {
    cursor: default;
}

.job_pending, .job_pending:hover {
    background: repeating-linear-gradient(
        45deg, /* Angle of the stripes */
        #1f71ff, /* Color 1 */
        #1f71ff 3px, /* Width of color 1 */
        #649bfb 3px, /* Color 2 */
        #649bfb 6px /* Width of color 2 */
    );
}

.grey_stripe_bg {
    background: repeating-linear-gradient(
        135deg, /* Angle of the stripes */
        #ffffff, /* Color 1 */
        #ffffff 6px, /* Width of color 1 */
        #f7f7f7 6px, /* Color 2 */
        #f7f7f7 12px /* Width of color 2 */
    );
}

.display_others_avails_datepicker .react-datepicker__day {
    cursor: default;
}

.display_others_avails_datepicker .react-datepicker__day, .display_others_avails_datepicker .react-datepicker__day:hover {
    background: #0ef112;
    border-radius: 0.3rem;
}

.display_others_avails_datepicker .react-datepicker__day--weekend, 
.display_others_avails_datepicker .react-datepicker__day--disabled {
    background: #f6f6f6;
    border-radius: 0.3rem;
}

.display_others_avails_datepicker .react-datepicker__day--weekend:hover,
.display_others_avails_datepicker .react-datepicker__day--disabled:hover {
    background: #f6f6f6;
    border-radius: 0.3rem;
}

.custom-datepicker {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.alert {
    border: 0 !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.fixed-top-md {
    height: 70vh;
    z-index: 1000;
}

.cursor-pointer {
    cursor: pointer;
}


@media (min-width: 768px) {
    .fixed-top-md {
      position: fixed;
      top: 0;
      left: 0;
      height: 98vh;
      z-index: 1000;
    }
  }

@media (max-width: 768px) {
    .toastMobile {
      width: 100% !important;
    }
}
